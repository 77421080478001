import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";

import Layout from "../components/Layout"
import { Container, CardContainer, Title } from '../components/atoms';
import { CallToAction, Tweet } from "../components/molecules";
import { Hero, News, Sponsors } from "../components/organisms";

const StyledNews = styled(News)`
    padding: 3em 0 4em;
    @media (max-width: 767px) {
        padding: 2em 0;
    }
`

const Tweets = styled.section`
    padding: 3em 0 4em;
    @media (max-width: 767px) {
        padding: 1em 0 2em;
    }
`

const App = ({ data }) => {
    const { hero, latestNews, promotion, sponsors, tweets } = data

    return (
        <Layout>
            <main>
                <article>
                    {hero.nodes.length > 0 && (
                        <Hero slides={hero.nodes} />
                    )}
                    {latestNews.nodes.length > 0 && (
                        <StyledNews articles={latestNews.nodes} homepage showHeader />
                    )}
                    {promotion.nodes.length > 0 && (
                        <CallToAction
                            title={promotion.nodes[0].title}
                            text={promotion.nodes[0].content}
                            link={promotion.nodes[0].linkToContent.wpInternal.uri || promotion.nodes[0].linkToContent.external}
                        />
                    )}
                    {tweets.nodes.length > 0 && (
                        <Tweets>
                            <Container maxWidth="73.125em" gutter>
                                <header>
                                    <Title>Tweet Ernehale Colts</Title>
                                </header>
                                <CardContainer>
                                    {tweets.nodes.map(tweet => <Tweet key={tweet.id} text={tweet.text} />)}
                                </CardContainer>
                            </Container>
                        </Tweets>
                    )}
                    {sponsors.nodes.length > 0 && (
                        <Sponsors sponsors={sponsors.nodes} />
                    )}
                </article>
            </main>
        </Layout>
    )
}

export const query = graphql`
    query HomePageQuery {
        hero: allWpPost(limit: 1) {
            nodes {
                id
                title
                uri
                hero {
                    desktopImage: image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1.77)
                            }
                        }
                        altText
                    }
                    tabletImage: image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(layout: CONSTRAINED, width: 1023)
                            }
                        }
                        altText
                    }
                    mobileImage: image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(layout: CONSTRAINED, width: 639)
                            }
                        }
                        altText
                    }
                }
            }
        }
        latestNews: allWpPost(limit: 5, sort: {fields: date, order: DESC}, skip: 1) {
            nodes {
                id
                title
                uri
                excerpt
                teams {
                    nodes {
                        id
                        uri
                        customFields{
                            abbreviation
                        }
                    }
                }
                landscapeImage: hero {
                    image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                        altText
                    }
                }
                portraitImage: hero {
                    image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    transformOptions: {cropFocus: CENTER}
                                    aspectRatio: 0.9
                                )
                            }
                        }
                        altText
                    }
                }
            }
        }
        promotion: allWpPromotion(sort: {fields: date, order: DESC}, limit: 1) {
            nodes {
                title
                content
                linkToContent {
                    wpInternal {
                        ... on WpPost {
                            uri
                        }
                        ... on WpPage {
                            uri
                        }
                        ... on WpFixture {
                            uri
                        }
                    }
                    external
                }
            }
        }
        sponsors: allWpSponsor(sort: {order: DESC, fields: date}, limit: 8) {
            nodes {
                id
                hero {
                    image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                        altText
                    }
                }
                linkToContent {
                    external
                }
            }
        }
        tweets: allTweet(limit: 3) {
            nodes {
                id
                text
            }
        }
    }
`

export default App
